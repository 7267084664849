import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M13 14H10C9.71667 14 9.47917 14.0959 9.2875 14.2875C9.09584 14.4792 9 14.7167 9 15C9 15.2834 9.09584 15.5209 9.2875 15.7125C9.47917 15.9042 9.71667 16 10 16H11C11 16.2834 11.0958 16.5209 11.2875 16.7125C11.4792 16.9042 11.7157 17 11.997 17C12.2784 17 12.5049 16.8982 12.6766 16.6946C12.8484 16.491 12.9562 16.2594 13 16H14C14.2833 16 14.5208 15.9042 14.7125 15.7125C14.9042 15.5209 15 15.2834 15 15V12C15 11.7167 14.9042 11.4792 14.7125 11.2875C14.5208 11.0959 14.2833 11 14 11H11V10H14C14.2833 10 14.5208 9.90419 14.7125 9.71253C14.9042 9.52086 15 9.28336 15 9.00003C15 8.71669 14.9042 8.47919 14.7125 8.28753C14.5208 8.09586 14.2833 8.00003 14 8.00003H13C13 7.71669 12.9042 7.47919 12.7125 7.28753C12.5208 7.09586 12.2843 7.00003 12.003 7.00003C11.7217 7.00003 11.4951 7.10184 11.3234 7.30545C11.1516 7.50909 11.0438 7.74061 11 8.00003H10C9.71667 8.00003 9.47917 8.09586 9.2875 8.28753C9.09584 8.47919 9 8.71669 9 9.00003V12C9 12.2834 9.09584 12.5209 9.2875 12.7125C9.47917 12.9042 9.71667 13 10 13H13V14ZM4.07175 20.2033C3.44204 20.2033 2.90544 19.9816 2.46195 19.5381C2.01849 19.0946 1.79675 18.558 1.79675 17.9283V6.07178C1.79675 5.44206 2.0185 4.90546 2.462 4.46198C2.90547 4.01849 3.44206 3.79675 4.07178 3.79675H19.9283C20.558 3.79675 21.0946 4.01849 21.5381 4.46198C21.9816 4.90546 22.2033 5.44206 22.2033 6.07178V17.9283C22.2033 18.558 21.9816 19.0946 21.5381 19.5381C21.0946 19.9816 20.558 20.2033 19.9283 20.2033H4.07175ZM4.07175 17.9283H19.9283V6.07178H4.07175V17.9283Z"/>
</svg>`;

const Money = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='money' svg={svg} {...props} />
  )
);

Money.displayName = 'MoneyIcon';

export default Money;
