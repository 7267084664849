/** @jsxImportSource react */
import { WizardsReviewPageMainProps } from '@react/wizards/genericWizard.types';
import { ComponentType, useContext } from 'react';

import WizardContext from './WizardContextProvider/WizardContext';

export default function withWizard(
  Component: ComponentType<WizardsReviewPageMainProps>
) {
  return function WizardComponent(props: WizardsReviewPageMainProps) {
    return (
      <WizardContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </WizardContext.Consumer>
    );
  };
}

export const useWizard = () => {
  return useContext(WizardContext);
};
