import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M10.5821 13.5908L8.5875 11.5962C8.38025 11.389 8.11901 11.2854 7.8038 11.2854C7.48858 11.2854 7.22735 11.389 7.0201 11.5962C6.81286 11.8035 6.71024 12.0647 6.71222 12.3799C6.71422 12.6951 6.81884 12.9564 7.02607 13.1636L9.78042 15.918C10.0098 16.1459 10.2773 16.2598 10.5831 16.2598C10.8889 16.2598 11.1558 16.1459 11.3837 15.918L16.944 10.3576C17.1513 10.1504 17.2549 9.89016 17.2549 9.57693C17.2549 9.26371 17.1513 9.00348 16.944 8.79623C16.7368 8.58898 16.4755 8.48535 16.1603 8.48535C15.8451 8.48535 15.5839 8.58898 15.3766 8.79623L10.5821 13.5908ZM12 22.2033C10.5848 22.2033 9.25691 21.9356 8.01637 21.4004C6.77584 20.8651 5.69675 20.1386 4.7791 19.2209C3.86145 18.3033 3.13497 17.2242 2.59967 15.9837C2.06437 14.7431 1.79672 13.4152 1.79672 12C1.79672 10.5848 2.06437 9.25694 2.59967 8.0164C3.13497 6.77587 3.86145 5.69678 4.7791 4.77913C5.69675 3.86148 6.77584 3.135 8.01637 2.5997C9.25691 2.0644 10.5848 1.79675 12 1.79675C13.4152 1.79675 14.7431 2.0644 15.9836 2.5997C17.2242 3.135 18.3032 3.86148 19.2209 4.77913C20.1385 5.69678 20.865 6.77587 21.4003 8.0164C21.9356 9.25694 22.2033 10.5848 22.2033 12C22.2033 13.4152 21.9356 14.7431 21.4003 15.9837C20.865 17.2242 20.1385 18.3033 19.2209 19.2209C18.3032 20.1386 17.2242 20.8651 15.9836 21.4004C14.7431 21.9357 13.4152 22.2033 12 22.2033ZM12 19.9283C14.2174 19.9283 16.0931 19.1613 17.6272 17.6272C19.1612 16.0931 19.9282 14.2174 19.9282 12C19.9282 9.78264 19.1612 7.90692 17.6272 6.37285C16.0931 4.8388 14.2174 4.07178 12 4.07178C9.78261 4.07178 7.90689 4.8388 6.37282 6.37285C4.83877 7.90692 4.07175 9.78264 4.07175 12C4.07175 14.2174 4.83877 16.0931 6.37282 17.6272C7.90689 19.1613 9.78261 19.9283 12 19.9283Z"/>
</svg>`;

const CheckCircleStroke = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='checkcirclestroke' svg={svg} {...props} />
  )
);

CheckCircleStroke.displayName = 'CheckCircleStrokeIcon';

export default CheckCircleStroke;
