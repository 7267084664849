/** @jsxImportSource react */
import { usePrefetch } from '@react/services/api';
import { useAuth } from '@react/services/auth';
import { DropdownButton, Link } from '@react/system';
import { USER_SAVE_INIT_STATUS } from '@sly/core/constants/userSaves';
import Favorite from '@sly/icons/react/Favorite';
import HomeSmallStroke from '@sly/icons/react/HomeSmallStroke';

function SavedIndicator({ count }: { count: number }) {
  return (
    <Link to='/homebase/saved' className='flex flex-row text-black'>
      <div className='relative mr-1'>
        <Favorite active className='text-red-base' />
        <span className='font-t-xxs-azo absolute left-0 top-0 h-6 w-6 text-center leading-6 text-white'>
          {count}
        </span>
      </div>
      <div>Saved</div>
    </Link>
  );
}

export default function HeaderLoggedInArea({
  showLogin,
  userName,
  isCustomer,
  toggleDropdown,
  menuOpen,
}: {
  showLogin: boolean;
  userName: string;
  isCustomer: boolean;
  toggleDropdown: () => void;
  menuOpen: boolean;
}) {
  const { user } = useAuth();
  const { requestInfo: requestInfoGUS } = usePrefetch(
    'getUserSaves',
    {
      'filter[status]': USER_SAVE_INIT_STATUS,
    },
    { shouldBail: !user }
  );
  const savedCommunities = requestInfoGUS?.normalized || [];
  if (showLogin) {
    if (isCustomer) {
      return (
        <div className='flex flex-row'>
          <SavedIndicator count={savedCommunities.length} />
          <Link to='/homebase' className='text-black'>
            <div className='ml-6'>
              <HomeSmallStroke />
              <span className='ml-1'>{userName}&apos;s Homebase</span>
            </div>
          </Link>
        </div>
      );
    }
    return (
      <DropdownButton
        onClick={toggleDropdown}
        isOpen={menuOpen}
        className='font-b-s !pr-s !pl-0'
        data-tp-id='header-menuitem-Log out'
      >
        {userName}
      </DropdownButton>
    );
  }
}
