import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8743 7.58677C10.5722 5.79097 10.5713 3.93008 11.8716 2.0041C13.7056 3.79061 13.7066 5.65151 11.8743 7.58677ZM11.8716 2.0041L11.8674 2H11.8743L11.8716 2.0041ZM0 11.4207C2.66667 10.3499 4.56544 11.1949 5.69633 13.9557C3.27167 14.4771 1.37289 13.6321 0 11.4207ZM9.089 17.8594C6.39907 19.9964 3.52065 20.1174 0.453748 18.2226H0.446768C3.09482 15.243 5.97556 15.1219 9.089 17.8594ZM11.8743 18.0619C11.3969 18.0545 10.9374 18.2439 10.6038 18.5857C10.2572 18.9276 10.0676 19.3978 10.0803 19.8846C10.0753 20.3556 10.2647 20.8078 10.6038 21.1347C10.9374 21.4764 11.3969 21.6658 11.8743 21.6584H11.986C12.1836 21.6575 12.3787 21.6147 12.5585 21.5327C13.2355 21.262 13.6803 20.607 13.6824 19.8776C13.6944 19.3923 13.5078 18.9231 13.1658 18.5787C12.8269 18.2327 12.3583 18.0451 11.8743 18.0619ZM11.1763 16.791C12.1629 10.4919 10.2292 6.60208 5.37522 5.12159C5.25887 10.7596 7.18092 14.6494 11.1414 16.791H11.1763ZM23.5462 18.2225C20.4747 20.1174 17.5963 19.9963 14.911 17.8594H14.8761C17.9849 15.1312 20.8749 15.2522 23.5462 18.2225ZM13.1379 16.791C17.0983 14.6447 19.0134 10.7549 18.8831 5.12159C14.0175 6.60907 12.1187 10.4919 13.103 16.791H13.1379ZM24 11.4207C22.6271 13.6321 20.7283 14.4771 18.3037 13.9557H18.2757C19.4066 11.1949 21.3147 10.3499 24 11.4207Z"/>
</svg>`;

const Seniorly = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='seniorly' svg={svg} {...props} />
  )
);

Seniorly.displayName = 'SeniorlyIcon';

export default Seniorly;
