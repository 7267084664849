import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M3.93425 18.2929C3.61503 18.2929 3.34564 18.1831 3.12607 17.9636C2.90651 17.744 2.79672 17.4746 2.79672 17.1554C2.79672 16.8362 2.90651 16.5668 3.12607 16.3473C3.34564 16.1277 3.61503 16.0179 3.93425 16.0179H20.0657C20.385 16.0179 20.6544 16.1277 20.8739 16.3473C21.0935 16.5668 21.2033 16.8362 21.2033 17.1554C21.2033 17.4746 21.0935 17.744 20.8739 17.9636C20.6544 18.1831 20.385 18.2929 20.0657 18.2929H3.93425ZM3.93425 13.1375C3.61503 13.1375 3.34564 13.0277 3.12607 12.8081C2.90651 12.5886 2.79672 12.3192 2.79672 12C2.79672 11.6808 2.90651 11.4114 3.12607 11.1918C3.34564 10.9723 3.61503 10.8625 3.93425 10.8625H20.0657C20.385 10.8625 20.6544 10.9723 20.8739 11.1918C21.0935 11.4114 21.2033 11.6808 21.2033 12C21.2033 12.3192 21.0935 12.5886 20.8739 12.8081C20.6544 13.0277 20.385 13.1375 20.0657 13.1375H3.93425ZM3.93425 7.98206C3.61503 7.98206 3.34564 7.87227 3.12607 7.65271C2.90651 7.43314 2.79672 7.16375 2.79672 6.84453C2.79672 6.52533 2.90651 6.25595 3.12607 6.03638C3.34564 5.81681 3.61503 5.70703 3.93425 5.70703H20.0657C20.385 5.70703 20.6544 5.81681 20.8739 6.03638C21.0935 6.25595 21.2033 6.52533 21.2033 6.84453C21.2033 7.16375 21.0935 7.43314 20.8739 7.65271C20.6544 7.87227 20.385 7.98206 20.0657 7.98206H3.93425Z"/>
</svg>`;

const Menu = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='menu' svg={svg} {...props} />
  )
);

Menu.displayName = 'MenuIcon';

export default Menu;
