/** @jsxImportSource react */
import { node } from 'proptypes';
import React, { useState } from 'react';

import WizardContext from './WizardContext';

const WizardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [stepToIndexMap, setStepToIndexMap] = useState<{
    [key: string]: number;
  }>({});

  const handleSetTotalPage = (numPages: number) => {
    setTotalPage(numPages);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const goToStep = (page: string) => {
    setCurrentPage(stepToIndexMap?.[page]);
  };

  const getCurrentStep = () => {
    return Object.keys(stepToIndexMap).find(
      (key) => stepToIndexMap[key] === currentPage
    );
  };

  const reset = () => {
    setCurrentPage(0);
    setTotalPage(0);
    setStepToIndexMap({});
  };

  const contextValues = {
    handleSetTotalPage,
    handleSetCurrentPage,
    setStepToIndexMap,
    goToStep,
    currentPage,
    getCurrentStep,
    totalPage,
    reset,
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <WizardContext.Provider value={contextValues}>
      {children}
    </WizardContext.Provider>
  );
};

WizardContextProvider.propTypes = {
  children: node,
};

export default WizardContextProvider;
