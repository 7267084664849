import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M7 14H13C13.2833 14 13.5208 13.9042 13.7125 13.7125C13.9042 13.5209 14 13.2834 14 13C14 12.7167 13.9042 12.4792 13.7125 12.2875C13.5208 12.0959 13.2833 12 13 12H7C6.71666 12 6.47916 12.0959 6.2875 12.2875C6.09583 12.4792 6 12.7167 6 13C6 13.2834 6.09583 13.5209 6.2875 13.7125C6.47916 13.9042 6.71666 14 7 14ZM7 11H17C17.2833 11 17.5208 10.9042 17.7125 10.7125C17.9042 10.5209 18 10.2834 18 10C18 9.71669 17.9042 9.47919 17.7125 9.28753C17.5208 9.09586 17.2833 9.00003 17 9.00003H7C6.71666 9.00003 6.47916 9.09586 6.2875 9.28753C6.09583 9.47919 6 9.71669 6 10C6 10.2834 6.09583 10.5209 6.2875 10.7125C6.47916 10.9042 6.71666 11 7 11ZM7 8.00003H17C17.2833 8.00003 17.5208 7.90419 17.7125 7.71253C17.9042 7.52086 18 7.28336 18 7.00003C18 6.71669 17.9042 6.47919 17.7125 6.28753C17.5208 6.09586 17.2833 6.00003 17 6.00003H7C6.71666 6.00003 6.47916 6.09586 6.2875 6.28753C6.09583 6.47919 6 6.71669 6 7.00003C6 7.28336 6.09583 7.52086 6.2875 7.71253C6.47916 7.90419 6.71666 8.00003 7 8.00003ZM5.79672 18.2033L3.73587 20.2642C3.37536 20.6247 2.96204 20.7061 2.49592 20.5084C2.02979 20.3108 1.79672 19.9578 1.79672 19.4495V4.07178C1.79672 3.44206 2.01847 2.90546 2.46197 2.46198C2.90544 2.01849 3.44203 1.79675 4.07175 1.79675H19.9282C20.558 1.79675 21.0946 2.01849 21.538 2.46198C21.9815 2.90546 22.2033 3.44206 22.2033 4.07178V15.9283C22.2033 16.558 21.9815 17.0946 21.538 17.5381C21.0946 17.9816 20.558 18.2033 19.9282 18.2033H5.79672ZM4.07175 15.9283H19.9282V4.07178H4.07175V15.9283Z"/>
</svg>`;

const ChatStroke = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='chatstroke' svg={svg} {...props} />
  )
);

ChatStroke.displayName = 'ChatStrokeIcon';

export default ChatStroke;
