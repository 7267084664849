import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M5.79672 18.2033L2.7663 21.2337C2.5797 21.4203 2.37145 21.466 2.14155 21.3707C1.91166 21.2754 1.79672 21.0971 1.79672 20.8359V4.07178C1.79672 3.44206 2.01847 2.90546 2.46197 2.46198C2.90544 2.01849 3.44203 1.79675 4.07175 1.79675H19.9282C20.558 1.79675 21.0946 2.01849 21.538 2.46198C21.9815 2.90546 22.2033 3.44206 22.2033 4.07178V15.9283C22.2033 16.558 21.9815 17.0946 21.538 17.5381C21.0946 17.9816 20.558 18.2033 19.9282 18.2033H5.79672ZM4.07175 15.9283H19.9282V4.07178H4.07175V15.9283ZM12 12.5348L13.7625 13.6011C13.9737 13.7337 14.183 13.7276 14.3902 13.5826C14.5975 13.4377 14.6721 13.243 14.6141 12.9984L14.15 10.9848L15.7244 9.61743C15.915 9.44749 15.972 9.24441 15.8954 9.00818C15.8187 8.77194 15.6498 8.6435 15.3886 8.62285L13.3266 8.46055L12.5157 6.55928C12.4202 6.32739 12.2482 6.21145 11.9998 6.21145C11.7514 6.21145 11.5795 6.32739 11.4842 6.55928L10.6733 8.46055L8.61142 8.62285C8.35019 8.6435 8.18126 8.77194 8.10462 9.00818C8.02799 9.24441 8.08496 9.44749 8.27555 9.61743L9.85 10.9848L9.38587 12.9984C9.32791 13.243 9.40254 13.4377 9.60977 13.5826C9.81702 13.7276 10.0263 13.7337 10.2375 13.6011L12 12.5348Z"/>
</svg>`;

const Reviews = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='reviews' svg={svg} {...props} />
  )
);

Reviews.displayName = 'ReviewsIcon';

export default Reviews;
