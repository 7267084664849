import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 20 20" fill="currentColor">
  <path d="M10.0001 9.91531C9.03364 9.91531 8.20823 9.57315 7.5239 8.88881C6.83956 8.20448 6.4974 7.37907 6.4974 6.41259C6.4974 5.4461 6.83956 4.62152 7.5239 3.93886C8.20823 3.25619 9.03364 2.91486 10.0001 2.91486C10.9666 2.91486 11.792 3.25619 12.4764 3.93886C13.1607 4.62152 13.5029 5.4461 13.5029 6.41259C13.5029 7.37907 13.1607 8.20448 12.4764 8.88881C11.792 9.57315 10.9666 9.91531 10.0001 9.91531ZM14.9403 16.9208H5.05992C4.53515 16.9208 4.08799 16.736 3.71842 16.3664C3.34885 15.9968 3.16406 15.5497 3.16406 15.0249V14.4081C3.16406 13.905 3.29422 13.4425 3.55454 13.0208C3.81485 12.5991 4.16069 12.2772 4.59206 12.0553C5.46313 11.6214 6.34969 11.2951 7.25173 11.0765C8.15377 10.8579 9.0699 10.7486 10.0001 10.7486C10.9367 10.7486 11.8561 10.8571 12.7583 11.074C13.6604 11.291 14.5438 11.6164 15.4082 12.0503C15.8396 12.2718 16.1854 12.5929 16.4457 13.0137C16.706 13.4345 16.8362 13.8993 16.8362 14.4081V15.0249C16.8362 15.5497 16.6514 15.9968 16.2818 16.3664C15.9123 16.736 15.4651 16.9208 14.9403 16.9208ZM5.05992 15.0249H14.9403V14.433C14.9403 14.2834 14.9021 14.1475 14.8257 14.0251C14.7494 13.9027 14.6487 13.8076 14.5237 13.7396C13.7936 13.3779 13.0516 13.1049 12.2977 12.9208C11.5438 12.7366 10.7779 12.6445 10.0001 12.6445C9.22899 12.6445 8.46313 12.7366 7.70256 12.9208C6.94201 13.1049 6.20001 13.3779 5.47658 13.7396C5.35158 13.8076 5.25089 13.9027 5.1745 14.0251C5.09811 14.1475 5.05992 14.2834 5.05992 14.433V15.0249ZM9.99996 8.01946C10.4418 8.01946 10.8201 7.86214 11.1349 7.5475C11.4496 7.23285 11.607 6.85461 11.607 6.41277C11.607 5.97092 11.4497 5.59345 11.135 5.28033C10.8204 4.96724 10.4421 4.81069 10.0003 4.81069C9.55846 4.81069 9.18016 4.96754 8.8654 5.28125C8.55063 5.59495 8.39325 5.97206 8.39325 6.41259C8.39325 6.85447 8.55057 7.23276 8.86521 7.54744C9.17986 7.86212 9.55811 8.01946 9.99996 8.01946Z"/>
</svg>`;

const User = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='user' svg={svg} {...props} />
  )
);

User.displayName = 'UserIcon';

export default User;
