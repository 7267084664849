import {
  ADMIN_DASHBOARD_AGENTS_PATH,
  AGENT_DASHBOARD_FAMILIES_PATH,
  AGENT_DASHBOARD_INVOICE_PATH,
  AGENT_DASHBOARD_LEAD_GENIE_PATH,
  AGENT_DASHBOARD_MESSAGES_PATH,
  AGENT_DASHBOARD_TASKS_PATH,
  DASHBOARD_ACCOUNT_PATH,
  DASHBOARD_COMMUNITIES_PATH,
  DASHBOARD_ORGS_PATH,
  DASHBOARD_PARTNER_COLLECTIONS_PATH,
  DASHBOARD_REVIEWS_PATH,
  DASHBOARD_USERS_PATH,
  FAMILY_DASHBOARD_FAVORITES_PATH,
  FAMILY_DASHBOARD_HOME_PATH,
  PARTNER_DASHBOARD_HOME_PATH,
} from '@sly/core/constants/dashboardAppPaths';
import {
  AGENT_ADMIN_ROLE,
  AGENT_ND_ROLE,
  COMMUNITY_MANAGERS_EMAIL_ONLY,
  CUSTOMER_ROLE,
  LEAD_GENIE_ROLE,
  PLATFORM_ADMIN_ROLE,
  PROVIDER_OD_ROLE,
} from '@sly/core/constants/roles';
import {
  Business,
  ChatStroke,
  CheckCircleStroke,
  CommunitiesStroke,
  Concierge,
  DataStroke,
  FamilyStroke,
  Favorite,
  HomeSmallStroke,
  Money,
  Organization,
  Reviews,
  SettingsStroke,
  User,
  Users,
} from '@sly/icons/react';
import React from 'react';

const menuItemFor = (menuItem) => {
  const { label } = menuItem;
  const event = {
    category: 'DashboardMenuItem',
    action: 'click',
    label,
  };
  return {
    iconSize: 'regular',
    color: 'text-slate-base',
    variation: 'filler',
    event,
    ...menuItem,
  };
};

const FavoriteActive = (props) => <Favorite active {...props} />;
/* eslint-disable no-bitwise */
export const menuItems = [
  {
    label: 'Families',
    Icon: FamilyStroke,
    href: AGENT_DASHBOARD_FAMILIES_PATH,
    role: AGENT_ND_ROLE | AGENT_ADMIN_ROLE | COMMUNITY_MANAGERS_EMAIL_ONLY,
  },
  {
    label: 'Agents',
    Icon: Business,
    href: ADMIN_DASHBOARD_AGENTS_PATH,
    role: PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Communities',
    Icon: CommunitiesStroke,
    href: DASHBOARD_COMMUNITIES_PATH,
    role: PLATFORM_ADMIN_ROLE | PROVIDER_OD_ROLE | AGENT_ADMIN_ROLE,
  },
  {
    label: "Partner C's",
    Icon: CommunitiesStroke,
    href: DASHBOARD_PARTNER_COLLECTIONS_PATH,
    role: PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Reviews',
    Icon: Reviews,
    href: DASHBOARD_REVIEWS_PATH,
    role: PLATFORM_ADMIN_ROLE | PROVIDER_OD_ROLE | AGENT_ND_ROLE,
  },
  {
    label: 'Tasks',
    Icon: CheckCircleStroke,
    href: AGENT_DASHBOARD_TASKS_PATH,
    role: AGENT_ND_ROLE | PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Users',
    Icon: Users,
    href: DASHBOARD_USERS_PATH,
    role: PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Organizations',
    Icon: Organization,
    href: DASHBOARD_ORGS_PATH,
    role: PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Messages',
    Icon: ChatStroke,
    href: AGENT_DASHBOARD_MESSAGES_PATH,
    role: PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Home Base',
    Icon: HomeSmallStroke,
    href: FAMILY_DASHBOARD_HOME_PATH,
    role: CUSTOMER_ROLE,
  },
  {
    label: 'Invoices',
    Icon: Money,
    href: AGENT_DASHBOARD_INVOICE_PATH,
    role: AGENT_ND_ROLE,
  },
  {
    label: 'Stats',
    Icon: DataStroke,
    href: PARTNER_DASHBOARD_HOME_PATH,
    role: PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Saved',
    Icon: Favorite,
    href: FAMILY_DASHBOARD_FAVORITES_PATH,
    role: CUSTOMER_ROLE,
  },
  {
    label: 'Lead Genie',
    Icon: Concierge,
    href: AGENT_DASHBOARD_LEAD_GENIE_PATH,
    role: LEAD_GENIE_ROLE | PLATFORM_ADMIN_ROLE,
  },
  {
    label: 'Settings',
    Icon: SettingsStroke,
    href: DASHBOARD_ACCOUNT_PATH,
    role: CUSTOMER_ROLE,
  },
  {
    label: 'Account',
    Icon: User,
    href: DASHBOARD_ACCOUNT_PATH,
    role: PLATFORM_ADMIN_ROLE,
  },
].map(menuItemFor);
/* eslint-enable no-bitwise */
