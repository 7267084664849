import { createContext } from 'react';

export interface WizardContextType {
  handleSetTotalPage: (numPages: number) => void;
  handleSetCurrentPage: (page: number) => void;
  setStepToIndexMap: (stepToIndexMap: { [key: string]: number }) => void;
  goToStep: (page: string) => void;
  getCurrentStep: () => string;
  totalPage: number;
  reset: () => void;
}

const WizardContext = createContext<WizardContextType>({} as WizardContextType);

export default WizardContext;
