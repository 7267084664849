/** @jsxImportSource react */

import { useWizard } from '@react/wizards/withWizard';
import Chevron from '@sly/icons/react/Chevron';
import { func, node, object, string } from 'proptypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Button } from 'sly/system';

const WizardForm = ({
  initialValues = {},
  children,
  onSubmit,
  submitText = 'Finish',
  hideButtons = false,
  initialStep = undefined,
  noDefaultSubmit = false,
}) => {
  const {
    handleSetTotalPage,
    handleSetCurrentPage,
    currentPage,
    setStepToIndexMap,
  } = useWizard();
  const [values, setValues] = useState(initialValues);

  const totalSteps = useMemo(() => React.Children.count(children), [children]);

  const activePage = useMemo(
    () => React.Children.toArray(children)[currentPage],
    [currentPage, children]
  );

  const isLastPage = useMemo(
    () => currentPage === totalSteps - 1,
    [currentPage, totalSteps]
  );

  const onNext = useCallback(
    (values) => {
      setValues(values);
      handleSetCurrentPage(
        Math.min(currentPage + 1, React.Children.toArray(children).length - 1)
      );
    },
    [currentPage, children]
  );

  const onBack = useCallback(
    () => handleSetCurrentPage(Math.max(currentPage - 1, 0)),
    [currentPage]
  );

  const validate = (values) =>
    activePage?.props?.validate ? activePage.props.validate(values) : {};

  const handleSubmit = (values) =>
    noDefaultSubmit ? () => {} : isLastPage ? onSubmit(values) : onNext(values);

  useEffect(() => {
    handleSetTotalPage(totalSteps);

    const stepToIndexMap = {};
    React.Children.toArray(children).forEach((child, index) => {
      if (child.props?.name) {
        stepToIndexMap[child.props.name] = index;
      }
    });
    setStepToIndexMap(stepToIndexMap);

    if (initialStep) {
      handleSetCurrentPage(stepToIndexMap[initialStep]);
    }
  }, [totalSteps]);

  return (
    <Form
      initialValues={values}
      validate={validate}
      onSubmit={handleSubmit}
      data-tp-id='gen-Form-756eaa06-7394-4c1a-84ba-b6b75345d9c1'
    >
      {(formProps) => {
        const { handleSubmit, submitting, invalid } = formProps;
        return (
          <form
            onSubmit={handleSubmit}
            data-tp-id='gen-form-418dedcf-12e9-4b6d-afca-ed3590c56785'
          >
            {React.isValidElement(activePage)
              ? React.cloneElement(activePage, { ...formProps })
              : null}
            {!hideButtons && (
              <div
                className='flex flex-row justify-between pt-2'
                data-tp-id='gen-Block-e3d37c65-973e-42f4-867f-a176e117556b'
              >
                {currentPage > 0 && (
                  <div
                    className='flex cursor-pointer flex-row items-center'
                    onClick={onBack}
                    data-tp-id='gen-Block-e7d48131-6c79-4546-83fc-3f7aad672f30'
                  >
                    <Chevron className='-rotate-90 text-viridian-base' />
                    <span
                      className='pl-xxs'
                      data-tp-id='gen-Block-356a127f-dd80-4df9-8150-abe6f97cbd64'
                    >
                      Back
                    </span>
                  </div>
                )}
                {!isLastPage && (
                  <Button
                    type='submit'
                    data-tp-id='gen-Button-25205eb8-b8cb-4ee5-8daf-1d9ae89066e0'
                  >
                    Continue
                  </Button>
                )}
                {isLastPage && (
                  <Button
                    type='submit'
                    disabled={submitting || invalid}
                    data-tp-id='gen-Button-f4f054a3-bbb6-44d8-ab04-96e03e4bce1d'
                  >
                    {submitText}
                  </Button>
                )}
              </div>
            )}
          </form>
        );
      }}
    </Form>
  );
};

WizardForm.propTypes = {
  initialValues: object,
  children: node,
  onSubmit: func,
  validate: func,
  submitText: string,
};

export default WizardForm;
