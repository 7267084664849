import {
  AGENT_ADMIN_ROLE,
  ORG_ADMIN_ROLE,
  PLATFORM_ADMIN_ROLE,
  PROVIDER_OD_ROLE
} from '@sly/core/constants/roles';

export const userIs = (user, role) => {
  // !! for converting result to boolean
  /* eslint-disable-next-line no-bitwise */
  return !!(user && user.roleID & role);
};

export const userIsPlatformAdmin = user => {
  // !! for converting result to boolean
  /* eslint-disable-next-line no-bitwise */
  return !!(user && user.roleID & PLATFORM_ADMIN_ROLE);
};

export const userIsProvider = user => {
  // !! for converting result to boolean
  /* eslint-disable-next-line no-bitwise */
  return !!(user && user.roleID & PROVIDER_OD_ROLE);
};

export const userIsOrgAdmin = user => {
  // !! for converting result to boolean
  /* eslint-disable-next-line no-bitwise */
  return !!(user && user.roleID & ORG_ADMIN_ROLE);
};

export const userExact = (user, role) => {
  /* eslint-disable-next-line no-bitwise */
  return user && user.roleID === role;
};

export const onlyAgentAdmin = user => {
  /* eslint-disable-next-line no-bitwise */
  return (
    !!(user && user.roleID & AGENT_ADMIN_ROLE) && !userIsPlatformAdmin(user)
  );
};

export const userIsAgentAdmin = user => {
  return user && user.roleID & AGENT_ADMIN_ROLE;
};
