import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M6.07175 18.9283H8.8625V14C8.8625 13.6808 8.97228 13.4114 9.19185 13.1919C9.41141 12.9723 9.6808 12.8625 10 12.8625H14C14.3192 12.8625 14.5886 12.9723 14.8081 13.1919C15.0277 13.4114 15.1375 13.6808 15.1375 14V18.9283H17.9282V10.0359L12 5.58969L6.07175 10.0359V18.9283ZM3.79672 18.9283V10.0359C3.79672 9.67636 3.87652 9.33575 4.03612 9.01407C4.19574 8.69238 4.41848 8.42592 4.70435 8.21469L10.6326 3.76849C11.0344 3.46197 11.4902 3.30872 12 3.30872C12.5098 3.30872 12.9656 3.46197 13.3674 3.76849L19.2956 8.21469C19.5815 8.42592 19.8043 8.69238 19.9639 9.01407C20.1235 9.33575 20.2033 9.67636 20.2033 10.0359V18.9283C20.2033 19.558 19.9815 20.0946 19.538 20.5381C19.0946 20.9816 18.558 21.2033 17.9282 21.2033H14.0897C13.7705 21.2033 13.5011 21.0935 13.2815 20.8739C13.062 20.6544 12.9522 20.385 12.9522 20.0658V15.0478H11.0478V20.0658C11.0478 20.385 10.938 20.6544 10.7185 20.8739C10.4989 21.0935 10.2295 21.2033 9.91032 21.2033H6.07175C5.44203 21.2033 4.90543 20.9816 4.46195 20.5381C4.01846 20.0946 3.79672 19.558 3.79672 18.9283Z"/>
</svg>`;

const HomeSmallStroke = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='homesmallstroke' svg={svg} {...props} />
  )
);

HomeSmallStroke.displayName = 'HomeSmallStrokeIcon';

export default HomeSmallStroke;
