/** @jsxImportSource react */
import { Info } from '@sly/icons/react';
import dynamic from 'next/dynamic';
import { isBrowser } from 'sly/config';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

const HeaderAgentAreaInfoIcon = () => {
  return (
    <>
      <Info
        className='ml-1 text-black'
        size='s'
        data-tip
        data-for='phoneNumber'
      />
      {isBrowser && (
        <ReactTooltip
          className='max-w-xs whitespace-normal bg-white p-xs text-slate-base shadow-sm'
          place='bottom'
          id='phoneNumber'
          type='light'
          effect='solid'
          multiline
        >
          Talk with a local advisor for free
        </ReactTooltip>
      )}
    </>
  );
};

export default HeaderAgentAreaInfoIcon;
