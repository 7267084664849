import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 20 20" fill="currentColor">
  <path d="M3.07178 19.2033C2.44206 19.2033 1.90546 18.9816 1.46198 18.5381C1.01849 18.0946 0.796753 17.558 0.796753 16.9283V3.07178C0.796753 2.44206 1.01849 1.90546 1.46198 1.46198C1.90546 1.01849 2.44206 0.796753 3.07178 0.796753H16.9283C17.558 0.796753 18.0946 1.01849 18.5381 1.46198C18.9816 1.90546 19.2033 2.44206 19.2033 3.07178V16.9283C19.2033 17.558 18.9816 18.0946 18.5381 18.5381C18.0946 18.9816 17.558 19.2033 16.9283 19.2033H3.07178ZM3.07178 16.9283H16.9283V3.07178H3.07178V16.9283ZM6.0359 8.00003C5.75257 8.00003 5.51507 8.09586 5.3234 8.28753C5.13174 8.47919 5.0359 8.71669 5.0359 9.00003V14C5.0359 14.2834 5.13174 14.5209 5.3234 14.7125C5.51507 14.9042 5.75257 15 6.0359 15C6.31924 15 6.55674 14.9042 6.7484 14.7125C6.94007 14.5209 7.0359 14.2834 7.0359 14V9.00003C7.0359 8.71669 6.94007 8.47919 6.7484 8.28753C6.55674 8.09586 6.31924 8.00003 6.0359 8.00003ZM10 5.00003C9.71669 5.00003 9.47919 5.09586 9.28753 5.28753C9.09586 5.47919 9.00003 5.71669 9.00003 6.00003V14C9.00003 14.2834 9.09586 14.5209 9.28753 14.7125C9.47919 14.9042 9.71669 15 10 15C10.2834 15 10.5209 14.9042 10.7125 14.7125C10.9042 14.5209 11 14.2834 11 14V6.00003C11 5.71669 10.9042 5.47919 10.7125 5.28753C10.5209 5.09586 10.2834 5.00003 10 5.00003ZM13.9642 11C13.6808 11 13.4433 11.0959 13.2517 11.2875C13.06 11.4792 12.9642 11.7167 12.9642 12V14C12.9642 14.2834 13.06 14.5209 13.2517 14.7125C13.4433 14.9042 13.6808 15 13.9642 15C14.2475 15 14.485 14.9042 14.6767 14.7125C14.8683 14.5209 14.9642 14.2834 14.9642 14V12C14.9642 11.7167 14.8683 11.4792 14.6767 11.2875C14.485 11.0959 14.2475 11 13.9642 11Z"/>
</svg>`;

const DataStroke = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='datastroke' svg={svg} {...props} />
  )
);

DataStroke.displayName = 'DataStrokeIcon';

export default DataStroke;
