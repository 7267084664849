import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M12.2033 17.293C12.512 17.293 12.7708 17.1885 12.9797 16.9797C13.1885 16.7708 13.2929 16.512 13.2929 16.2033V12.2929C13.2929 11.9842 13.1885 11.7254 12.9797 11.5166C12.7708 11.3077 12.512 11.2033 12.2033 11.2033C11.8945 11.2033 11.6357 11.3077 11.4269 11.5166C11.218 11.7254 11.1136 11.9842 11.1136 12.2929V16.2033C11.1136 16.512 11.218 16.7708 11.4269 16.9797C11.6357 17.1885 11.8945 17.293 12.2033 17.293ZM12.2033 9.32285C12.5205 9.32285 12.7864 9.21556 13.001 9.00098C13.2156 8.78639 13.3229 8.52049 13.3229 8.20327C13.3229 7.88606 13.2156 7.62016 13.001 7.40557C12.7864 7.19099 12.5205 7.0837 12.2033 7.0837C11.8861 7.0837 11.6202 7.19099 11.4056 7.40557C11.191 7.62016 11.0837 7.88606 11.0837 8.20327C11.0837 8.52049 11.191 8.78639 11.4056 9.00098C11.6202 9.21556 11.8861 9.32285 12.2033 9.32285ZM12.2033 22.4065C10.7881 22.4065 9.46018 22.1389 8.21965 21.6036C6.97912 21.0683 5.90003 20.3418 4.98237 19.4242C4.06472 18.5065 3.33825 17.4274 2.80295 16.1869C2.26765 14.9464 2 13.6185 2 12.2033C2 10.7881 2.26765 9.46018 2.80295 8.21965C3.33825 6.97912 4.06472 5.90002 4.98237 4.98237C5.90003 4.06472 6.97912 3.33825 8.21965 2.80295C9.46018 2.26765 10.7881 2 12.2033 2C13.6185 2 14.9464 2.26765 16.1869 2.80295C17.4274 3.33825 18.5065 4.06472 19.4242 4.98237C20.3418 5.90002 21.0683 6.97912 21.6036 8.21965C22.1389 9.46018 22.4065 10.7881 22.4065 12.2033C22.4065 13.6185 22.1389 14.9464 21.6036 16.1869C21.0683 17.4274 20.3418 18.5065 19.4242 19.4242C18.5065 20.3418 17.4274 21.0683 16.1869 21.6036C14.9464 22.1389 13.6185 22.4065 12.2033 22.4065ZM12.2033 20.1315C14.4207 20.1315 16.2964 19.3645 17.8304 17.8305C19.3645 16.2964 20.1315 14.4207 20.1315 12.2033C20.1315 9.98589 19.3645 8.11017 17.8304 6.5761C16.2964 5.04205 14.4207 4.27502 12.2033 4.27502C9.98589 4.27502 8.11017 5.04205 6.5761 6.5761C5.04205 8.11017 4.27502 9.98589 4.27502 12.2033C4.27502 14.4207 5.04205 16.2964 6.5761 17.8305C8.11017 19.3645 9.98589 20.1315 12.2033 20.1315Z"/>
</svg>`;

const Info = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='info' svg={svg} {...props} />
  )
);

Info.displayName = 'InfoIcon';

export default Info;
